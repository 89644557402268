import React, { useState } from "react";
import Head from "next/head";
import { useRouter } from "next/router";

import Header from "components/Header";
import Aside from "components/Aside";
import { APP_PAGE_TITLE_POSTFIX } from "constants/index";
import { useBookingsPlatformStore } from "store/booking-platform";
import { classNames } from "utils";
import Loader from "components/common/Loader";
import { useThemeContext } from "store";
import { ImpersonatedUserBar } from "../v2/ImpersonatedUserBar";
import { useAuth } from "hooks/use-auth";
import { TabLayout } from "../v2/TabNav/TabLayout";

export interface LayoutProps {
    children?: React.ReactNode;
    title?: string;
    noPadding?: boolean;
}

const LayoutV1 = ({ children, title = "", noPadding = false }: LayoutProps): JSX.Element => {
    const router = useRouter();

    const { initLoadingHotelComponent, jumpVisibilityState, currentSupplierId, hiddenList } =
        useBookingsPlatformStore();

    const { mainContainerRef } = useThemeContext();

    const [navbarOpen, setNavbarOpen] = useState(false);

    const routerSupplierType = router.query.supplierType as string[];

    const isHiddenNavigation =
        currentSupplierId &&
        routerSupplierType?.[1] === "hotel" &&
        (jumpVisibilityState || (!jumpVisibilityState && initLoadingHotelComponent) || hiddenList);

    return (
        <div className="min-h-screen">
            <Head>
                <title>{title ? `${title} - ${APP_PAGE_TITLE_POSTFIX}` : ""}</title>
            </Head>
            <style jsx global>{`
                .container {
                    max-width: 65rem;
                    margin: 1.5rem auto;
                    padding-left: 1rem;
                    padding-right: 1rem;
                }
            `}</style>
            <ImpersonatedUserBar />
            <Aside isOpen={navbarOpen} hideNavbar={() => setNavbarOpen(false)} />
            <div
                id="main-container"
                className={
                    "md:min-h-screen md:h-[100vh] md:overflow-y-auto lg:pl-64 md:flex md:flex-col md:flex-1 overscroll-y-contain"
                }
                ref={mainContainerRef}
            >
                <Header showNavbar={() => setNavbarOpen(true)} />
                <main className={classNames("flex-1", isHiddenNavigation && "invisible")}>
                    <div className={noPadding ? "" : "p-4 md:py-12 md:px-16"}>{children}</div>
                </main>
                {isHiddenNavigation && <Loader isSpin className={"lg:ml-64"} />}
            </div>
        </div>
    );
};

export default function Layout(props: LayoutProps) {
    const { isFeatureEnabled } = useAuth();
    return isFeatureEnabled("nav_v3") ? (
        <TabLayout
            {...props}
            className={
                props.noPadding ? "" : "px-4 pt-2 tablet:px-0 tablet:pt-10 tablet:[&>div]:mb-12"
            }
        />
    ) : (
        <LayoutV1 {...props} />
    );
}
